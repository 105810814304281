@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

span.nav-link {
  position: relative;
  cursor: pointer;
}

span.nav-link::after {
  content: "";
  width: 0%;
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.2s;
  box-shadow: inset 0 -10px 0 #000;
}

span.nav-link:hover::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 -10px 0 #000;
}

span.nav-link-thick {
  position: relative;
  cursor: pointer;
}

span.nav-link-thick::after {
  content: "";
  width: 0%;
  height: 3px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px;
  transition: 0.2s;
  box-shadow: inset 0 -10px 0 #000;
}

span.nav-link-thick.active::after {
  content: "";
  width: 100%;
  height: 3px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px;
  transition: 0.2s;
  box-shadow: inset 0 -10px 0 #000;
}

span.nav-link-thick:hover::after {
  content: "";
  width: 100%;
  height: 3px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px;
  box-shadow: inset 0 -10px 0 #000;
}

span.nav-link-black {
  position: relative;
  cursor: pointer;
}

span.nav-link-black::after {
  content: "";
  width: 0%;
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.2s;
  box-shadow: inset 0 -10px 0 #fff;
}

span.nav-link-black:hover::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 -10px 0 #fff;
}
